import React from 'react';
// components
import HeroSection from '../../sections/Product/components/common/HeroSection';
import SecondaryInfoSection from '../../sections/Product/components/common/SecondaryInfoSection';
import {
    criminalCheckBulletPoints,
    criminalCheckFaq,
    criminalCheckInfoCards,
} from '../../sections/Product/components/utilities';
import BuildTrust from '../../sections/Product/components/common/BuildTrust';
import ProductSeo from '../../components/ProductSeo';
import Coverage from '../../sections/Product/CriminalBgChecks/Coverage';
import HelpInfoSection from '../../sections/Product/CriminalBgChecks/HelpInfoSection';
import ProductFaq from '../../sections/Product/components/common/ProductFaq';
import Schedule from '../../sections/Product/components/common/Schedule';
// assets
import Criminal_checks_demo from '../../images/product-assets/criminal-background-checks.gif';
import Criminal_check_flow from '../../images/product-assets/criminal-check-flow.svg';
// layout
import RootLayout from '../../layouts/RootLayout';

function CriminalChecks() {
    return (
        <>
            <ProductSeo
                title="Comprehensive Background Criminal Checks | Authenticate®"
                description="Explore Authenticate® for thorough background screenings with our Criminal Record Indicator, 7-year Checks, and detailed County and Federal Court searches. Ensure safety and compliance with reliable, up-to-date records."
                ogImage="https://cdn.authenticating.com/public/website/product_pages/criminal_background_checks_og_image.jpg"
            />
            <RootLayout>
                <HeroSection
                    tag="Products · Criminal Background Check"
                    heading="Mitigate Risks with Criminal Background Checks"
                    subHeading="Authenticate® offers instant and more comprehensive background checks for enhanced security. With options for both more comprehensive and instant criminal background checks, Authenticate® provides enhanced security while minimizing costs."
                    heroImage={Criminal_checks_demo}
                />
                <SecondaryInfoSection
                    title="Detailed Instant reports to boost security and reduce fraud"
                    desc1="Criminal background checks help mitigate risks, ensure compliance, enhance safety, and protect reputation by identifying individuals with criminal histories, enabling informed decision-making, and safeguarding businesses and stakeholders."
                    desc2="This set of solutions ranges from criminal record indicators to the past seven years of criminal activity. Along with thorough federal and county-level checks, this service ensures thorough and reliable criminal background verification to keep your business safe and secure."
                    bulletPoints={criminalCheckBulletPoints}
                    sectionImage={Criminal_check_flow}
                />
                <BuildTrust cardInfo={criminalCheckInfoCards} />
                <Coverage />
                <HelpInfoSection />
                <ProductFaq
                    faqData={criminalCheckFaq}
                    subHeading="Everything you need to know about the product and billing."
                />
                <Schedule />
            </RootLayout>
        </>
    );
}

export default CriminalChecks;
